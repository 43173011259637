<template>
  <v-app>
    <base-app-bar />
    <v-progress-linear
      v-if="$store.state.loading"
      :height="4"
      :indeterminate="true"
      style="position:absolute;width:100% !important;z-index: 10;"
    />
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">
            mdi-bell-outline
          </v-icon> {{ $t("Demande d'autorisation") }}
          <v-spacer />
          <v-btn
            small
            color="red"
            outlined
            @click="dialog=false"
          >
            {{ $t("Fermer") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ $t("Souhaitez-vous recevoir des notifications de notre site web ?") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            color="default"
            @click="dontShowRequest"
          >
            {{ $t("Refuser") }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="registerNotificationManager"
          >
            {{ $t("Autoriser") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-view />

    <footer-view />
    <mobile-navigation />
  </v-app>
</template>

<script>
  export default {
    name: 'BaseLayout',
    components: {
      BaseAppBar: () => import('./AppBar'),
      BaseView: () => import('./View'),
      FooterView: () => import('./Footer'),
      MobileNavigation: () => import('./MobileNavigationBar'),
    },
    data: () => ({
      dialog: false,
    }),
    created: function () {
      var notificationsAsked = window.localStorage.getItem('noti_asked')
      if (notificationsAsked !== 'dont') {
        this.dialog = true
      }
    },
    methods: {
      dontShowRequest () {
        this.dialog = false
        window.localStorage.setItem('noti_asked', 'dont')
      },
      registerNotificationManager () {
        this.dialog = false
        if ('Notification' in window && navigator.serviceWorker) {
          Notification.requestPermission(function (status) {
            console.log('Notification permission status:', status)
          })

          if (Notification.permission === 'granted') {
            window.localStorage.setItem('noti_asked', 'dont')
            navigator.serviceWorker.ready.then(function (reg) {
            })
          }
        }
      },
    },
  }
</script>
